<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->


<template>
    <div>
        <CModal
            :title="$t('network.tranzactionParamsModalTitle')"
            :show.sync="isShowModal"
            :closeOnBackdrop="false"
            class="hide-close-button"
        >
          <CSelect
            label="Orderer*"
            :options="ordererForSelect"
            horizontal
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.orderer"
            :isValid="isValid.orderer"
            :tooltipFeedback=false
            :invalidFeedback="validationMessages.required"
            @change="$v.form.orderer.$touch()"
          />

          <CSelect
            label="Fabric-admin-tool*"
            :options="fabricAdminToolForSelect"
            horizontal
            required
            placeholder=" "
            addLabelClasses="font-weight-bold"
            :value.sync="form.fabricAdminTool"
            :isValid="isValid.fabricAdminTool"
            :tooltipFeedback=false
            :invalidFeedback="validationMessages.required"
            @change="$v.form.fabricAdminTool.$touch()"
          />

          <div slot="footer" class="footer-buttons">
              <CButton
                  type="button"
                  class="btn btn-secondary"
                  @click="closeModal"
              > {{ $t('common.cancel') }} </CButton>
              <CButton
                  type="button"
                  class="btn btn-primary"
                  :disabled="!isFormValid"
                  @click="createParam"
              > {{ $t('common.create') }} </CButton>
          </div>
      </CModal>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import messages from '../messages';


export default {
  name: 'ModalParam',

  props: {
    isShowModal: Boolean,
    ordererList: Array,
    fabricAdminToolList: Array
  },
  data() {
    return {
      validationMessages: messages.validation,
      form: {
        orderer: null,
        fabricAdminTool: null
      },
    }
  },
  validations: {
    form: {
      orderer: {
        required
      },
      fabricAdminTool: {
        required
      }
    }
  },
  computed: {
    isValid() {
      let orderer, fabricAdminTool

      if (this.$v.form.orderer.$dirty)
        orderer = !this.$v.form.orderer.$invalid

      if (this.$v.form.fabricAdminTool.$dirty)
        fabricAdminTool = !this.$v.form.fabricAdminTool.$invalid

      return {
        orderer,
        fabricAdminTool
      }
    },
    isFormValid() {
      return !this.$v.form.$invalid
    },
    ordererForSelect() {
      return this.ordererList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    },
    fabricAdminToolForSelect() {
      return this.fabricAdminToolList.map(item => {
        return {
          label: item.hostname,
          value: item.id
        }
      })
    }
  },

  methods: {
    closeModal() {
        this.form = {
          orderer: null,
          fabricAdminTool: null,
          peer: null,
        }
        this.$v.$reset()
        this.$emit('closeModal')
    },
    createParam() {
      const data = {
        orderer_endpoint_id: this.form.orderer,
        admin_tool_endpoint_id: this.form.fabricAdminTool,
      }
      this.$emit('createParam', data)
      this.closeModal()
    }
  },
};
</script>

<style scoped>
  .footer-buttons button {
    margin-left: 5px;
  }

</style>
