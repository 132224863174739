<template>
  <CCard>
    <CCardHeader>
      <div class="header d-flex justify-content-between">
        <div class="header__title">
          <strong>{{ $t("network.changeTransactionTitle") }}</strong>
        </div>
        <div class="header__actions" v-if="transaction">
          <NewUpdateChangeModal
            class="p-0 m-0"
            :transaction-id="transaction.id"
            :update-form="true"
            :step="transaction.steps[0]"
            @save="updateTransaction($event)"
          >
          </NewUpdateChangeModal>
        </div>
      </div>
    </CCardHeader>
    <CCardBody>
      <div
        v-if="!transaction"
        class="d-flex align-tems-center justify-content-center"
      >
        <fa-icon
          icon="sync"
          size="1x"
          class="endless-animation"
          style="color: black"
        />
      </div>

      <div v-if="transaction" class="transaction-info mb-2">
        <p class="transaction-info__item item p-0 m-0">
          <span class="item__label"
            >{{ $t("channel.groupUnderChange") }}:
          </span>
          <span class="item__value">"{{ changedGroupName }}"</span>
        </p>
        <p class="transaction-info__item p-0 m-0">
          <span class="item__label"
            >{{ $t("channel.channelChangePolicy") }}:
          </span>
          <span class="item__value">{{
            channelConfigurationUpdateTypeName
          }}</span>
        </p>
        <div class="transaction-info__item p-0 m-0">
          <span class="item__label"
            >{{ $t("channel.transactionSignatures") }} ({{
              transaction.signatures.length
            }}
            / {{ network.consortium.length }}) :</span
          >
          <ul class="signature-list" v-if="transaction.signatures.length > 0">
            <li
              class="signature-list__item"
              v-for="(item, index) in transaction.signatures"
              :id="index"
            >
              <a
                v-on:click.prevent="
                  downloadCertificate(item.pem, item.common_name)
                "
                href="#"
                >{{ item.common_name }}</a
              >
              <span>({{ item.mspid }})</span>
            </li>
          </ul>
          <span v-else class="item__value">
            {{ $t("channel.emptyTransactionSignatures") }}
          </span>
        </div>
      </div>

      <TransactionDiff
        v-if="transaction"
        :oldVal="transactionStep.old_json || ''"
        :newVal="transaction.config_json || ''"
      >
      </TransactionDiff>
    </CCardBody>
    <CCardFooter class="card-footer">
      <CButton
        color="dark"
        variant="outline"
        @click.prevent="cancelTransaction()"
      >
        <div class="icon-wrapper d-flex align-items-center">
          <CIcon name="cil-x-circle" />
          <span class="ml-1">{{ $t("common.cancel") }}</span>
        </div>
      </CButton>
      <CButton
        color="dark"
        :disabled="!transaction || !canImportExport"
        variant="outline"
        @click.prevent="saveTransactionToFile()"
      >
        <div class="icon-wrapper d-flex align-items-center">
          <CIcon name="cil-arrow-thick-from-bottom" />
          <span class="ml-1">{{ $t("common.export") }}</span>
        </div>
      </CButton>
      <import-transaction-modal
        :disabled="!transaction || !canImportExport"
      ></import-transaction-modal>
      <CButton
        color="dark"
        :disabled="!transaction || !canRunTransaction"
        variant="outline"
        @click.prevent="showTransactionParametersModal()"
      >
        <div class="icon-wrapper d-flex align-items-center">
          <CIcon name="cil-flight-takeoff" />
          <span class="ml-1">{{ $t("common.start") }}</span>
        </div>
      </CButton>
    </CCardFooter>

    <ModalParam
      v-if="transaction"
      :isShowModal="isShowModalParam"
      :ordererList="modalParam.ordererList"
      :fabricAdminToolList="modalParam.fabricAdminToolList"
      :submitLabel="$t('common.save')"
      @closeModal="onCloseModal"
      @createParam="onCreateParam"
    />
  </CCard>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import utilMessages from "@/utils/messages";
import { createFileNameWithDate, saveFile } from "@/utils/system";

import ImportTransactionModal from "../common/ImportTransactionModal";
import NewUpdateChangeModal from "../common/NewUpdateChangeModal";
import {
ChannelConfigUpdateType,
TRANSACTION_QUORUM,
ZERO_TRANZACTION_ID
} from "../common/transaction/constants";
import TransactionDiff from "../common/TransactionDiff";
import ModalParam from "./ModalParam";

export default {
  name: "ChangeTransacrion",
  components: {
    TransactionDiff,
    ImportTransactionModal,
    ModalParam,
    NewUpdateChangeModal,
  },
  data() {
    return {
      transaction: null,
      topicId: null,
      networkId: null,
      network: null,
      isShowModalParam: false,
      modalParam: {
        fabricAdminToolList: [],
        ordererList: [],
      },
    };
  },
  async mounted() {
    this.topicId = this.$route.query.topic_id;
    const subscription = await this.subscribe({
      key: this.topicId ? this.topicId : "CreateUpdateTransaction",
      handler: (_) => {
        subscription.unsubscribe();
        this.loadNetwork(this.networkId)
      },
    });

    if (this.$route.params.id) {
      this.loadNetwork(this.$route.params.id);
    } else {
      this.$router.push({ path: "/dashboard" });
    }
  },
  methods: {
    ...mapActions([
      "fetchNetworks",
      "fetchTransaction",
      "fetchEndpoints",
      "updateTransaction",
      "cancelUpdateTransaction",
      "exportTransaction",
      "executeTransaction",
      "subscribeTopic",
      "subscribeOnTopicByType",
      "subscribe",
    ]),
    async cancelTransaction() {
      try {
        await this.cancelUpdateTransaction(this.networkId);
        this.$toast.success(this.$i18n.t("channel.changeCancelled"));
        this.$router.push({ path: `/networks/${this.networkId}` });
      } catch (error) {
        this.$toast.error(utilMessages.errMessage(error));
      }
    },
    async saveTransactionToFile() {
      try {
        const result = await this.exportTransaction(this.transaction.id);
        const content = JSON.stringify(result);
        const fileName = createFileNameWithDate(this.network.name);
        saveFile(content, fileName);
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async showTransactionParametersModal() {
      await this.getOrderer();
      await this.getFabricAdminTool();
      await this.getPeer();
      this.isShowModalParam = true;
    },
    onCloseModal() {
      this.isShowModalParam = false;
      this.isShowModalParamAdminTool = false;
    },
    async getOrderer() {
      try {
        this.modalParam.ordererList = await this.fetchEndpoints(
          `blockchain_net_id=${this.networkId}&type=orderer`
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getFabricAdminTool() {
      try {
        this.modalParam.fabricAdminToolList = await this.fetchEndpoints(
          "status=attached&current_org=true&type=fabric-admin-tools"
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async getPeer() {
      try {
        this.modalParam.peerList = await this.fetchEndpoints(
          "type=peer&current_org=true"
        );
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async onCreateParam(param) {
      param["channel_id"] = this.networkId;
      try {
        let response = await this.executeTransaction({
          transactionId: this.transaction.id,
          data: param,
        });
        this.$router.push({ path: `/topics/${response.topic_id}` });
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
    async loadNetwork(networkId) {
      this.networkId = networkId;
      await this.fetchNetworks();
      this.network = this.getNetwork(this.networkId);
    },
    async updateTransaction(updatedTransaction) {
      let newSteps = [...this.transaction.steps];
      newSteps[0].json_path = updatedTransaction.jsonpath;
      newSteps[0].json = btoa(updatedTransaction.newChange);

      let transactionData = { ...this.transaction };
      transactionData.steps = newSteps;

      const data = {
        transactionId: this.transaction.id,
        data: transactionData,
      };
      try {
        await this.updateTransaction(data);
        this.$toast.success(this.$i18n.t("channel.changeAdded"));
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err));
      }
    },
  },
  computed: {
    ...mapGetters(["getNetwork", "getUser"]),
    transactionStep() {
      return this.transaction ? this.transaction.steps[0] : null;
    },
    canImportExport() {
      const orAndExclusiveUpdateType = [
        ChannelConfigUpdateType.OR,
        ChannelConfigUpdateType.EXCLUSIVE,
      ];
      return !orAndExclusiveUpdateType.includes(
        this.network.blockchain_update_policy_type
      );
    },
    canRunTransaction() {
      switch (this.network.blockchain_update_policy_type) {
        case ChannelConfigUpdateType.OR.code:
          return true;
        case ChannelConfigUpdateType.MAJORITY.code:
          const alreadySignedCount = this.transaction.signatures?.length;
          const consortium = this.network.consortium.length;
          return (
            Math.round((alreadySignedCount / consortium) * 100) >
            TRANSACTION_QUORUM
          );
        case ChannelConfigUpdateType.EXCLUSIVE.code:
          return (
            this.getUser.org_id === this.network.network_administrator_org_id
          );
        default:
          return false;
      }
    },
    changedGroupName() {
      return !this.transactionStep ? "" : this.transactionStep.json_path;
    },
    channelConfigurationUpdateTypeName() {
      return (
        ChannelConfigUpdateType[this.network.blockchain_update_policy_type]
          ?.text ?? "-"
      );
    },
  },
  watch: {
    network: async function (newValue, oldValue) {
      if (
        !newValue ||
        !newValue.config_update_transaction_id ||
        newValue.config_update_transaction_id === ZERO_TRANZACTION_ID
      ) {
        return;
      }

      this.transaction = await this.fetchTransaction(
        newValue.config_update_transaction_id
      );
    },
  },
};
</script>

<style lang="scss">
.endless-animation {
  margin: 10px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.card-footer > *:not(:last-child) {
  margin-right: 25px;
}

.signature-list {
  list-style: none;
  padding: 0;
  padding-left: 7px;
}

.item__label {
  font-weight: 700;
}

.item__value {
  color: #838282;
}

.signature-list__item {
  color: #838282;
}
</style>
